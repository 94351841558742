// src/theme.js

import { createTheme, alpha } from '@mui/material/styles';

// Custom color palette
const orangePalette = {
  50: '#fff3e0',
  100: '#ffe0b2',
  200: '#ffcc80',
  300: '#ffb74d',
  400: '#ffa726',
  500: '#ff9800', // Main orange
  600: '#fb8c00',
  700: '#f57c00',
  800: '#ef6c00',
  900: '#e65100',
};

// Nature theme (green) palette
const greenPalette = {
  50: '#e8f5e9',
  100: '#c8e6c9',
  200: '#a5d6a7',
  300: '#81c784',
  400: '#66bb6a',
  500: '#4caf50', // Main green
  600: '#43a047',
  700: '#388e3c',
  800: '#2e7d32',
  900: '#1b5e20',
};

// Professional theme (blue) palette
const bluePalette = {
  50: '#e3f2fd',
  100: '#bbdefb',
  200: '#90caf9',
  300: '#64b5f6',
  400: '#42a5f5',
  500: '#2196f3', // Main blue
  600: '#1e88e5',
  700: '#1976d2',
  800: '#1565c0',
  900: '#0d47a1',
};

// Theme configuration
const themeConfig = {
  light: {
    primary: {
      main: orangePalette[500],
      light: orangePalette[300],
      dark: orangePalette[700],
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f5f7fa',
      paper: '#ffffff',
      card: '#ffffff',
      gradient: 'linear-gradient(120deg, #f8f9fa 0%, #e9ecef 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f0f0f0' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      primary: '#2d3748',
      secondary: '#718096',
    },
    error: {
      main: '#ef4444',
      light: '#f87171',
      dark: '#dc2626',
    },
    warning: {
      main: '#f59e0b',
      light: '#fbbf24',
      dark: '#d97706',
    },
    info: {
      main: '#3b82f6',
      light: '#60a5fa',
      dark: '#2563eb',
    },
    success: {
      main: '#10b981',
      light: '#34d399',
      dark: '#059669',
    },
    divider: alpha('#718096', 0.12),
  },
  dark: {
    primary: {
      main: orangePalette[400],
      light: orangePalette[300],
      dark: orangePalette[600],
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#90caf9',
      light: '#bbdefb',
      dark: '#64b5f6',
      contrastText: '#1a1a1a',
    },
    background: {
      default: '#111827',
      paper: '#1f2937',
      card: '#1f2937',
      gradient: 'linear-gradient(120deg, #111827 0%, #1e293b 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23232f3e' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      primary: '#f3f4f6',
      secondary: '#d1d5db',
    },
    error: {
      main: '#f87171',
      light: '#fca5a5',
      dark: '#ef4444',
    },
    warning: {
      main: '#fbbf24',
      light: '#fcd34d',
      dark: '#f59e0b',
    },
    info: {
      main: '#60a5fa',
      light: '#93c5fd',
      dark: '#3b82f6',
    },
    success: {
      main: '#34d399',
      light: '#6ee7b7',
      dark: '#10b981',
    },
    divider: alpha('#d1d5db', 0.12),
  },
  // Nature theme (light variant)
  natureLight: {
    primary: {
      main: greenPalette[500],
      light: greenPalette[300],
      dark: greenPalette[700],
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff7043', // Coral orange as complementary color
      light: '#ffa270',
      dark: '#c63f17',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f1f8e9', // Light green tinted background
      paper: '#ffffff',
      card: '#ffffff',
      gradient: 'linear-gradient(120deg, #f1f8e9 0%, #dcedc8 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23c5e1a5' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      primary: '#33691e', // Dark green for better readability
      secondary: '#558b2f',
    },
    error: {
      main: '#ef5350',
      light: '#ff867c',
      dark: '#b61827',
    },
    warning: {
      main: '#ffc107',
      light: '#fff350',
      dark: '#c79100',
    },
    info: {
      main: '#03a9f4',
      light: '#67daff',
      dark: '#007ac1',
    },
    success: {
      main: '#66bb6a',
      light: '#98ee99',
      dark: '#338a3e',
    },
    divider: alpha('#33691e', 0.12),
  },
  // Nature theme (dark variant)
  natureDark: {
    primary: {
      main: greenPalette[400],
      light: greenPalette[300],
      dark: greenPalette[600],
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6e40', // Brighter coral for contrast in dark mode
      light: '#ffa06d',
      dark: '#c53d13',
      contrastText: '#ffffff',
    },
    background: {
      default: '#1b2a1b', // Very dark green
      paper: '#263c28',
      card: '#263c28',
      gradient: 'linear-gradient(120deg, #1b2a1b 0%, #2e432e 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%234b6b4c' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      primary: '#e8f5e9',
      secondary: '#aed581',
    },
    error: {
      main: '#ff8a80',
      light: '#ffbcaf',
      dark: '#c85a54',
    },
    warning: {
      main: '#ffe57f',
      light: '#ffffb0',
      dark: '#cab350',
    },
    info: {
      main: '#80d8ff',
      light: '#b5ffff',
      dark: '#49a7cc',
    },
    success: {
      main: '#b9f6ca',
      light: '#ecfffd',
      dark: '#88c399',
    },
    divider: alpha('#aed581', 0.12),
  },
  // Professional theme (light variant)
  professionalLight: {
    primary: {
      main: bluePalette[600],
      light: bluePalette[400],
      dark: bluePalette[800],
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#546e7a', // Bluish gray
      light: '#819ca9',
      dark: '#29434e',
      contrastText: '#ffffff',
    },
    background: {
      default: '#eceff1', // Blue gray tinted background
      paper: '#ffffff',
      card: '#ffffff',
      gradient: 'linear-gradient(120deg, #f5f5f5 0%, #e0e0e0 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23b0bec5' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      primary: '#263238', // Dark blue gray
      secondary: '#455a64',
    },
    error: {
      main: '#e53935',
      light: '#ff6f60',
      dark: '#ab000d',
    },
    warning: {
      main: '#fb8c00',
      light: '#ffbd45',
      dark: '#c25e00',
    },
    info: {
      main: '#0288d1',
      light: '#5eb8ff',
      dark: '#005b9f',
    },
    success: {
      main: '#00897b',
      light: '#4ebaaa',
      dark: '#005b4f',
    },
    divider: alpha('#546e7a', 0.12),
  },
  // Professional theme (dark variant)
  professionalDark: {
    primary: {
      main: bluePalette[400],
      light: bluePalette[300],
      dark: bluePalette[600],
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#78909c', // Lighter blue gray for dark mode
      light: '#a7c0cd',
      dark: '#4b636e',
      contrastText: '#ffffff',
    },
    background: {
      default: '#102027', // Very dark blue gray
      paper: '#263238',
      card: '#263238',
      gradient: 'linear-gradient(120deg, #102027 0%, #1c313a 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23455a64' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      primary: '#eceff1',
      secondary: '#b0bec5',
    },
    error: {
      main: '#ef9a9a',
      light: '#ffcccb',
      dark: '#ba6b6c',
    },
    warning: {
      main: '#ffcc80',
      light: '#ffffb0',
      dark: '#ca9b52',
    },
    info: {
      main: '#81d4fa',
      light: '#b6ffff',
      dark: '#4ba3c7',
    },
    success: {
      main: '#80cbc4',
      light: '#b2fef7',
      dark: '#4f9a94',
    },
    divider: alpha('#b0bec5', 0.12),
  },
};

// Custom shadows for light mode
const lightShadows = [
  'none',
  '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
  '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
  '0px 4px 6px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.1)',
  '0px 10px 15px rgba(0, 0, 0, 0.06), 0px 20px 25px rgba(0, 0, 0, 0.1)',
  '0px 20px 25px rgba(0, 0, 0, 0.06), 0px 40px 50px rgba(0, 0, 0, 0.1)',
];

// Custom shadows for dark mode
const darkShadows = [
  'none',
  '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.25)',
  '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.25)',
  '0px 4px 6px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.25)',
  '0px 10px 15px rgba(0, 0, 0, 0.2), 0px 20px 25px rgba(0, 0, 0, 0.25)',
  '0px 20px 25px rgba(0, 0, 0, 0.2), 0px 40px 50px rgba(0, 0, 0, 0.25)',
];

// Create theme function
export const createAppTheme = (mode = 'light', themeFamily = 'default') => {
  let colors;
  
  // Determine which color scheme to use based on theme family and mode
  if (themeFamily === 'nature') {
    colors = mode === 'light' ? themeConfig.natureLight : themeConfig.natureDark;
  } else if (themeFamily === 'professional') {
    colors = mode === 'light' ? themeConfig.professionalLight : themeConfig.professionalDark;
  } else {
    // Default orange theme
    colors = mode === 'light' ? themeConfig.light : themeConfig.dark;
  }
  
  const shadows = mode === 'light' ? lightShadows : darkShadows;

  return createTheme({
    palette: {
      mode,
      ...colors,
    },
    typography: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 600,
        lineHeight: 1.2,
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.2,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.2,
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.5,
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.5,
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
      },
      button: {
        textTransform: 'none',
        fontWeight: 600,
      },
    },
    shape: {
      borderRadius: 8,
    },
    shadows,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: 'none',
            fontWeight: 600,
            padding: '8px 16px',
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: shadows[1],
            },
          },
          text: {
            color: mode === 'light' ? colors.text.primary : colors.text.primary,
            '&:hover': {
              backgroundColor: alpha(colors.primary.main, 0.1),
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.background.paper,
            color: colors.text.primary,
            boxShadow: shadows[1],
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            '& .MuiButton-root': {
              color: colors.text.primary,
              '&:hover': {
                backgroundColor: alpha(colors.primary.main, 0.1),
              },
              '&.active': {
                color: colors.primary.main,
                backgroundColor: alpha(colors.primary.main, 0.1),
              },
            },
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            color: mode === 'light' ? colors.text.secondary : colors.text.primary,
            '&.Mui-selected': {
              color: colors.primary.main,
            },
          },
          label: {
            fontSize: '0.75rem',
            '&.Mui-selected': {
              fontSize: '0.75rem',
            },
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            backgroundColor: colors.background.paper,
            borderTop: `1px solid ${colors.divider}`,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: shadows[1],
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '24px',
            '&:last-child': {
              paddingBottom: '24px',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 6,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${colors.divider}`,
          },
          head: {
            fontWeight: 600,
            backgroundColor: mode === 'light' 
              ? alpha(colors.primary.main, 0.05)
              : alpha(colors.primary.main, 0.1),
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
            fontSize: '0.75rem',
          },
        },
      },
    },
  });
};

// Create the themes
export const modernLightTheme = createAppTheme('light', 'default');
export const modernDarkTheme = createAppTheme('dark', 'default');
export const natureLightTheme = createAppTheme('light', 'nature');
export const natureDarkTheme = createAppTheme('dark', 'nature');
export const professionalLightTheme = createAppTheme('light', 'professional');
export const professionalDarkTheme = createAppTheme('dark', 'professional');
