import React from 'react';
import { Box, useTheme } from '@mui/material';
import { styled, keyframes } from '@mui/system';

// Animation keyframes
const float1 = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(15px, -15px) rotate(5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

const float2 = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(-15px, 10px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

const float3 = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(10px, 10px) rotate(3deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.7; }
  50% { transform: scale(1.05); opacity: 0.9; }
  100% { transform: scale(1); opacity: 0.7; }
`;

// Styled components for the shapes
const Shape = styled(Box)(({ theme, color, size, top, left, animation, opacity = 0.7 }) => ({
  position: 'fixed',
  width: size,
  height: size,
  borderRadius: '50%',
  backgroundColor: color,
  top: top,
  left: left,
  opacity: opacity,
  filter: 'blur(8px)',
  zIndex: -1,
  animation: `${animation} 15s infinite ease-in-out`,
}));

const Circle = styled(Box)(({ theme, color, size, top, right, animation, opacity = 0.5 }) => ({
  position: 'fixed',
  width: size,
  height: size,
  borderRadius: '50%',
  backgroundColor: 'transparent',
  border: `2px solid ${color}`,
  top: top,
  right: right,
  opacity: opacity,
  zIndex: -1,
  animation: `${animation} 20s infinite ease-in-out`,
}));

const Blob = styled(Box)(({ theme, color, width, height, top, right, animation, opacity = 0.6 }) => ({
  position: 'fixed',
  width: width,
  height: height,
  borderRadius: '60% 40% 70% 30% / 50% 60% 40% 50%',
  backgroundColor: color,
  top: top,
  right: right,
  opacity: opacity,
  filter: 'blur(12px)',
  zIndex: -1,
  animation: `${animation} 25s infinite ease-in-out`,
}));

const AnimatedBackground = ({ isDarkMode, backgroundStyle = 'default' }) => {
  const theme = useTheme();
  
  // Get colors based on theme
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;
  const accentColor = isDarkMode ? '#2a4365' : '#ebf4ff';
  
  // Adjust opacity based on background style
  const getOpacity = () => {
    switch (backgroundStyle) {
      case 'blueprint':
      case 'geometric':
        return 0.3; // More subtle for structured patterns
      case 'warm':
      case 'cool':
        return 0.4; // Medium opacity for colored backgrounds
      default:
        return 0.6; // Default opacity
    }
  };
  
  // Skip animation for certain styles
  if (backgroundStyle === 'blueprint') {
    return null; // Blueprint style looks better without floating elements
  }
  
  const opacity = getOpacity();
  
  return (
    <>
      {/* Floating shapes */}
      <Shape 
        color={primaryColor} 
        size="150px" 
        top="10%" 
        left="5%" 
        animation={float1}
        opacity={opacity}
      />
      <Shape 
        color={secondaryColor} 
        size="100px" 
        top="70%" 
        left="15%" 
        animation={float2}
        opacity={opacity}
      />
      <Circle 
        color={primaryColor} 
        size="200px" 
        top="20%" 
        right="10%" 
        animation={float3}
        opacity={opacity * 0.8}
      />
      <Blob 
        color={accentColor} 
        width="300px" 
        height="300px" 
        top="60%" 
        right="5%" 
        animation={pulse}
        opacity={opacity}
      />
    </>
  );
};

export default AnimatedBackground; 