/**
 * Collection of background styles that can be applied to the application
 * Each style includes patterns and gradients for both light and dark modes
 */

const backgroundStyles = {
  // Default style with subtle patterns
  default: {
    light: {
      gradient: 'linear-gradient(120deg, #f8f9fa 0%, #e9ecef 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f0f0f0' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    dark: {
      gradient: 'linear-gradient(120deg, #111827 0%, #1e293b 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23232f3e' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    }
  },
  
  // Blueprint style with grid patterns
  blueprint: {
    light: {
      gradient: 'linear-gradient(120deg, #e6f2ff 0%, #f0f7ff 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23b3d9ff' fill-opacity='0.3'%3E%3Cpath d='M0 0h1v1H0zM0 10h1v1H0zM0 20h1v1H0zM0 30h1v1H0zM0 40h1v1H0zM0 50h1v1H0zM0 60h1v1H0zM0 70h1v1H0zM0 80h1v1H0zM0 90h1v1H0zM10 0h1v1h-1zM10 10h1v1h-1zM10 20h1v1h-1zM10 30h1v1h-1zM10 40h1v1h-1zM10 50h1v1h-1zM10 60h1v1h-1zM10 70h1v1h-1zM10 80h1v1h-1zM10 90h1v1h-1zM20 0h1v1h-1zM20 10h1v1h-1zM20 20h1v1h-1zM20 30h1v1h-1zM20 40h1v1h-1zM20 50h1v1h-1zM20 60h1v1h-1zM20 70h1v1h-1zM20 80h1v1h-1zM20 90h1v1h-1zM30 0h1v1h-1zM30 10h1v1h-1zM30 20h1v1h-1zM30 30h1v1h-1zM30 40h1v1h-1zM30 50h1v1h-1zM30 60h1v1h-1zM30 70h1v1h-1zM30 80h1v1h-1zM30 90h1v1h-1zM40 0h1v1h-1zM40 10h1v1h-1zM40 20h1v1h-1zM40 30h1v1h-1zM40 40h1v1h-1zM40 50h1v1h-1zM40 60h1v1h-1zM40 70h1v1h-1zM40 80h1v1h-1zM40 90h1v1h-1zM50 0h1v1h-1zM50 10h1v1h-1zM50 20h1v1h-1zM50 30h1v1h-1zM50 40h1v1h-1zM50 50h1v1h-1zM50 60h1v1h-1zM50 70h1v1h-1zM50 80h1v1h-1zM50 90h1v1h-1zM60 0h1v1h-1zM60 10h1v1h-1zM60 20h1v1h-1zM60 30h1v1h-1zM60 40h1v1h-1zM60 50h1v1h-1zM60 60h1v1h-1zM60 70h1v1h-1zM60 80h1v1h-1zM60 90h1v1h-1zM70 0h1v1h-1zM70 10h1v1h-1zM70 20h1v1h-1zM70 30h1v1h-1zM70 40h1v1h-1zM70 50h1v1h-1zM70 60h1v1h-1zM70 70h1v1h-1zM70 80h1v1h-1zM70 90h1v1h-1zM80 0h1v1h-1zM80 10h1v1h-1zM80 20h1v1h-1zM80 30h1v1h-1zM80 40h1v1h-1zM80 50h1v1h-1zM80 60h1v1h-1zM80 70h1v1h-1zM80 80h1v1h-1zM80 90h1v1h-1zM90 0h1v1h-1zM90 10h1v1h-1zM90 20h1v1h-1zM90 30h1v1h-1zM90 40h1v1h-1zM90 50h1v1h-1zM90 60h1v1h-1zM90 70h1v1h-1zM90 80h1v1h-1zM90 90h1v1h-1z'/%3E%3C/g%3E%3C/svg%3E")`,
    },
    dark: {
      gradient: 'linear-gradient(120deg, #0a192f 0%, #172a46 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23193366' fill-opacity='0.3'%3E%3Cpath d='M0 0h1v1H0zM0 10h1v1H0zM0 20h1v1H0zM0 30h1v1H0zM0 40h1v1H0zM0 50h1v1H0zM0 60h1v1H0zM0 70h1v1H0zM0 80h1v1H0zM0 90h1v1H0zM10 0h1v1h-1zM10 10h1v1h-1zM10 20h1v1h-1zM10 30h1v1h-1zM10 40h1v1h-1zM10 50h1v1h-1zM10 60h1v1h-1zM10 70h1v1h-1zM10 80h1v1h-1zM10 90h1v1h-1zM20 0h1v1h-1zM20 10h1v1h-1zM20 20h1v1h-1zM20 30h1v1h-1zM20 40h1v1h-1zM20 50h1v1h-1zM20 60h1v1h-1zM20 70h1v1h-1zM20 80h1v1h-1zM20 90h1v1h-1zM30 0h1v1h-1zM30 10h1v1h-1zM30 20h1v1h-1zM30 30h1v1h-1zM30 40h1v1h-1zM30 50h1v1h-1zM30 60h1v1h-1zM30 70h1v1h-1zM30 80h1v1h-1zM30 90h1v1h-1zM40 0h1v1h-1zM40 10h1v1h-1zM40 20h1v1h-1zM40 30h1v1h-1zM40 40h1v1h-1zM40 50h1v1h-1zM40 60h1v1h-1zM40 70h1v1h-1zM40 80h1v1h-1zM40 90h1v1h-1zM50 0h1v1h-1zM50 10h1v1h-1zM50 20h1v1h-1zM50 30h1v1h-1zM50 40h1v1h-1zM50 50h1v1h-1zM50 60h1v1h-1zM50 70h1v1h-1zM50 80h1v1h-1zM50 90h1v1h-1zM60 0h1v1h-1zM60 10h1v1h-1zM60 20h1v1h-1zM60 30h1v1h-1zM60 40h1v1h-1zM60 50h1v1h-1zM60 60h1v1h-1zM60 70h1v1h-1zM60 80h1v1h-1zM60 90h1v1h-1zM70 0h1v1h-1zM70 10h1v1h-1zM70 20h1v1h-1zM70 30h1v1h-1zM70 40h1v1h-1zM70 50h1v1h-1zM70 60h1v1h-1zM70 70h1v1h-1zM70 80h1v1h-1zM70 90h1v1h-1zM80 0h1v1h-1zM80 10h1v1h-1zM80 20h1v1h-1zM80 30h1v1h-1zM80 40h1v1h-1zM80 50h1v1h-1zM80 60h1v1h-1zM80 70h1v1h-1zM80 80h1v1h-1zM80 90h1v1h-1zM90 0h1v1h-1zM90 10h1v1h-1zM90 20h1v1h-1zM90 30h1v1h-1zM90 40h1v1h-1zM90 50h1v1h-1zM90 60h1v1h-1zM90 70h1v1h-1zM90 80h1v1h-1zM90 90h1v1h-1z'/%3E%3C/g%3E%3C/svg%3E")`,
    }
  },
  
  // Geometric style with triangular patterns
  geometric: {
    light: {
      gradient: 'linear-gradient(120deg, #f5f7fa 0%, #e4e8f0 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23c5cfe0' fill-opacity='0.3'%3E%3Cpath d='M0 0l40 40-40 40zM40 40l40 40V0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    dark: {
      gradient: 'linear-gradient(120deg, #1a202c 0%, #2d3748 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233f4c6b' fill-opacity='0.3'%3E%3Cpath d='M0 0l40 40-40 40zM40 40l40 40V0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    }
  },
  
  // Warm style with orange/amber tones
  warm: {
    light: {
      gradient: 'linear-gradient(120deg, #fffaf0 0%, #feebc8 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f6ad55' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    },
    dark: {
      gradient: 'linear-gradient(120deg, #3c2a1e 0%, #4a3520 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23dd6b20' fill-opacity='0.2'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    }
  },
  
  // Cool style with blue/teal tones
  cool: {
    light: {
      gradient: 'linear-gradient(120deg, #e6fffa 0%, #b2f5ea 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%234fd1c5' fill-opacity='0.2'%3E%3Cpath d='M0 0h20L0 20z'/%3E%3Cpath d='M20 0v20H0z' fill-opacity='0.2'/%3E%3C/g%3E%3C/svg%3E")`,
    },
    dark: {
      gradient: 'linear-gradient(120deg, #1a535c 0%, #2c7a7b 100%)',
      pattern: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2338b2ac' fill-opacity='0.2'%3E%3Cpath d='M0 0h20L0 20z'/%3E%3Cpath d='M20 0v20H0z' fill-opacity='0.2'/%3E%3C/g%3E%3C/svg%3E")`,
    }
  }
};

export default backgroundStyles; 