import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Grid,
  Typography,
  IconButton,
  useTheme,
  Tooltip,
  Zoom
} from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import CloseIcon from '@mui/icons-material/Close';
import backgroundStyles from '../theme/backgroundStyles';

// This component allows users to select different background styles
const BackgroundStyler = ({ onStyleChange, currentStyle = 'default' }) => {
  const [open, setOpen] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(currentStyle);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStyleSelect = (styleName) => {
    setSelectedStyle(styleName);
    onStyleChange(styleName);
    handleClose();
  };

  // Preview component for each background style
  const StylePreview = ({ styleName, style }) => {
    const currentThemeStyle = isDarkMode ? style.dark : style.light;
    const isSelected = selectedStyle === styleName;
    
    return (
      <Grid item xs={6} sm={4} md={4}>
        <Tooltip 
          title={styleName.charAt(0).toUpperCase() + styleName.slice(1)} 
          placement="top"
          TransitionComponent={Zoom}
        >
          <Box
            onClick={() => handleStyleSelect(styleName)}
            sx={{
              height: 100,
              borderRadius: 2,
              cursor: 'pointer',
              overflow: 'hidden',
              position: 'relative',
              transition: 'transform 0.2s, box-shadow 0.2s',
              transform: isSelected ? 'scale(0.95)' : 'scale(1)',
              boxShadow: isSelected 
                ? `0 0 0 3px ${theme.palette.primary.main}` 
                : '0 2px 8px rgba(0,0,0,0.1)',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
              },
              backgroundImage: `${currentThemeStyle.pattern}, ${currentThemeStyle.gradient}`,
              backgroundSize: 'auto, cover',
              backgroundAttachment: 'fixed',
            }}
          >
            {isSelected && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                  borderBottomLeftRadius: 8,
                  px: 1,
                  py: 0.5,
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                }}
              >
                Active
              </Box>
            )}
          </Box>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <>
      <Tooltip title="Change Background Style">
        <IconButton 
          onClick={handleOpen}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[3],
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            zIndex: 1300,
          }}
        >
          <PaletteIcon />
        </IconButton>
      </Tooltip>

      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Choose Background Style
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body2" color="text.secondary" paragraph>
            Select a background style to personalize your experience.
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(backgroundStyles).map(([styleName, style]) => (
              <StylePreview 
                key={styleName} 
                styleName={styleName} 
                style={style} 
              />
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BackgroundStyler; 