// src/App.js

import React, {useState, useEffect, Suspense, lazy} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Container,
    CssBaseline,
    ThemeProvider,
    SwipeableDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Typography,
    Divider,
    Switch,
    useMediaQuery,
    BottomNavigation,
    BottomNavigationAction,
    GlobalStyles,
    Paper,
    Menu,
    MenuItem,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PaletteIcon from '@mui/icons-material/Palette';
import {AuthProvider, useAuth} from './context/AuthContext';
import {AppProvider} from './context/AppContext';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import deLocale from 'date-fns/locale/de';
import {alpha, keyframes} from '@mui/material/styles';

// Import modern themes
import {
    modernLightTheme, 
    modernDarkTheme, 
    natureLightTheme, 
    natureDarkTheme, 
    professionalLightTheme, 
    professionalDarkTheme
} from './theme';

// Import assets
import logo from './assets/logo.webp';
import favicon from './assets/Favicon.png';

// Import background components
import AnimatedBackground from './components/AnimatedBackground';
import BackgroundStyler from './components/BackgroundStyler';
import backgroundStyles from './theme/backgroundStyles';

// Lazy load your pages and components
const Home = lazy(() => import('./pages/Home'));
const JobsPage = lazy(() => import('./pages/JobsPage'));
const CalendarPage = lazy(() => import('./pages/CalendarPage'));
const ConsumablesPage = lazy(() => import('./pages/ConsumablesPage'));
const JobOverviewPage = lazy(() => import('./pages/JobOverviewPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const Login = lazy(() => import('./components/Login'));
const ToolsPage = lazy(() => import('./pages/ToolsPage'));
const CutOptimizer = lazy(() => import('./pages/CutOptimizer'));
const CashBook = lazy(() => import('./pages/CashBook'));
const MemoryGame = lazy(() => import('./pages/MemoryGame'));
const QuoteGenerator = lazy(() => import('./pages/QuoteGenerator'));
const JobCostCalculator = lazy(() => import('./pages/JobCostCalculator'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute'));
// Commented out missing pages
// const LoginPage = lazy(() => import('./pages/LoginPage'));
// const WorkerPage = lazy(() => import('./pages/WorkerPage'));

// iOS Detection for SwipeableDrawer performance optimization
const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

// Define pulse animation
const pulseAnimation = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(66, 133, 244, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
  }
`;

// Simple Loading Screen component
const LoadingScreen = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
    <Typography variant="h6" sx={{ ml: 2 }}>
      Wird geladen...
    </Typography>
  </Box>
);

// Function to provide haptic feedback on navigation tap
const triggerHapticFeedback = () => {
  if (window.navigator && window.navigator.vibrate) {
    // Vibrate for 50ms for a subtle feedback
    window.navigator.vibrate(50);
  }
};

// Main Content Component
function AppContent() {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [themeFamily, setThemeFamily] = useState('default');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [backgroundStyle, setBackgroundStyle] = useState('default');
    const [themeMenuAnchor, setThemeMenuAnchor] = useState(null);
    const {currentUser, logout} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [bottomNavValue, setBottomNavValue] = useState(0);
    const [navBarScale, setNavBarScale] = useState(1);
    
    // Get current theme based on theme family and mode
    const getTheme = () => {
        if (themeFamily === 'nature') {
            return isDarkMode ? natureDarkTheme : natureLightTheme;
        } else if (themeFamily === 'professional') {
            return isDarkMode ? professionalDarkTheme : professionalLightTheme;
        } else {
            // Default theme
            return isDarkMode ? modernDarkTheme : modernLightTheme;
        }
    };
    
    const theme = getTheme();

    // Initialize theme and background style from localStorage
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        setIsDarkMode(savedTheme === 'dark');
        
        const savedThemeFamily = localStorage.getItem('themeFamily');
        if (savedThemeFamily) {
            setThemeFamily(savedThemeFamily);
        }
        
        const savedBackgroundStyle = localStorage.getItem('backgroundStyle');
        if (savedBackgroundStyle) {
            setBackgroundStyle(savedBackgroundStyle);
        }
    }, []);

    // Toggle between light and dark mode
    const toggleTheme = () => {
        const newTheme = !isDarkMode ? 'dark' : 'light';
        setIsDarkMode(!isDarkMode);
        localStorage.setItem('theme', newTheme);
        
        // Update the background immediately when theme changes
        const currentStyle = backgroundStyles[backgroundStyle];
        const newThemeStyle = !isDarkMode ? currentStyle.dark : currentStyle.light;
        document.body.style.backgroundImage = `${newThemeStyle.pattern}, ${newThemeStyle.gradient}`;
    };
    
    // Change theme family
    const changeThemeFamily = (family) => {
        setThemeFamily(family);
        localStorage.setItem('themeFamily', family);
        setThemeMenuAnchor(null);
    };
    
    // Open theme menu
    const openThemeMenu = (event) => {
        setThemeMenuAnchor(event.currentTarget);
    };
    
    // Close theme menu
    const closeThemeMenu = () => {
        setThemeMenuAnchor(null);
    };
    
    // Change background style
    const handleBackgroundStyleChange = (styleName) => {
        setBackgroundStyle(styleName);
        localStorage.setItem('backgroundStyle', styleName);
    };

    // Toggle Drawer
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    // Handle Logout
    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    // Navigation Links
    const navLinks = [
        {title: 'Startseite', path: '/', icon: <HomeIcon/>},
        {title: 'Aufträge', path: '/jobs', icon: <WorkIcon/>},
        {title: 'Kalender', path: '/calendar', icon: <CalendarTodayIcon/>},
        {title: 'Bestellliste', path: '/consumables', icon: <LocalOfferIcon/>},
        {title: 'Tools', path: '/tools', icon: <BuildIcon/>},
        {title: 'Einstellungen', path: '/settings', icon: <SettingsIcon/>},
    ];

    // Update Bottom Navigation based on current route
    useEffect(() => {
        const currentIndex = navLinks.findIndex(link => link.path === location.pathname);
        setBottomNavValue(currentIndex !== -1 ? currentIndex : 0);
    }, [location.pathname, navLinks]);

    // Add scroll listener for navbar scale effect
    useEffect(() => {
        if (isMobile) {
            const handleScroll = () => {
                const scrollPosition = window.scrollY;
                // Scale down slightly when scrolling down (min scale: 0.95)
                const newScale = Math.max(0.95, 1 - scrollPosition / 2000);
                setNavBarScale(newScale);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [isMobile]);

    // Side Effects: Setting favicon, document title, and meta description
    useEffect(() => {
        // Set favicon
        const faviconLink = document.querySelector("link[rel='icon']");
        if (faviconLink) {
            faviconLink.href = favicon;
        } else {
            const newFavicon = document.createElement('link');
            newFavicon.rel = 'icon';
            newFavicon.href = favicon;
            document.head.appendChild(newFavicon);
        }

        // Set document title
        document.title = 'NiroApp';

        // Set meta description
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.content = 'NiroApp';
        } else {
            const newMeta = document.createElement('meta');
            newMeta.name = 'description';
            newMeta.content = 'NiroApp';
            document.head.appendChild(newMeta);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {/* Global Styles */}
            <GlobalStyles
                styles={{
                    '*': {
                        boxSizing: 'border-box',
                    },
                    body: {
                        margin: 0,
                        padding: 0,
                        backgroundColor: isDarkMode
                            ? theme.palette.background.default
                            : theme.palette.background.default,
                        backgroundImage: `${isDarkMode 
                            ? backgroundStyles[backgroundStyle].dark.pattern + ',' + backgroundStyles[backgroundStyle].dark.gradient
                            : backgroundStyles[backgroundStyle].light.pattern + ',' + backgroundStyles[backgroundStyle].light.gradient}`,
                        backgroundAttachment: 'fixed',
                        backgroundSize: 'auto, cover',
                        fontFamily: theme.typography.fontFamily,
                    },
                    a: {
                        color: isDarkMode
                            ? theme.palette.primary.main
                            : theme.palette.primary.main,
                        textDecoration: 'none',
                    },
                }}
            />
            
            {/* Animated Background */}
            <AnimatedBackground isDarkMode={isDarkMode} backgroundStyle={backgroundStyle} />
            
            {/* Theme Selection Menu */}
            <Menu
                anchorEl={themeMenuAnchor}
                open={Boolean(themeMenuAnchor)}
                onClose={closeThemeMenu}
                sx={{ mt: 1 }}
            >
                <MenuItem 
                    onClick={() => changeThemeFamily('default')} 
                    selected={themeFamily === 'default'}
                    sx={{ 
                        color: themeFamily === 'default' ? modernLightTheme.palette.primary.main : 'inherit',
                        fontWeight: themeFamily === 'default' ? 'bold' : 'normal',
                    }}
                >
                    Default (Orange)
                </MenuItem>
                <MenuItem 
                    onClick={() => changeThemeFamily('nature')} 
                    selected={themeFamily === 'nature'}
                    sx={{ 
                        color: themeFamily === 'nature' ? natureLightTheme.palette.primary.main : 'inherit',
                        fontWeight: themeFamily === 'nature' ? 'bold' : 'normal',
                    }}
                >
                    Nature (Green)
                </MenuItem>
                <MenuItem 
                    onClick={() => changeThemeFamily('professional')} 
                    selected={themeFamily === 'professional'}
                    sx={{ 
                        color: themeFamily === 'professional' ? professionalLightTheme.palette.primary.main : 'inherit',
                        fontWeight: themeFamily === 'professional' ? 'bold' : 'normal',
                    }}
                >
                    Professional (Blue)
                </MenuItem>
                <Divider />
                <MenuItem onClick={toggleTheme}>
                    {isDarkMode ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Brightness7Icon sx={{ mr: 1 }} /> Light Mode
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Brightness4Icon sx={{ mr: 1 }} /> Dark Mode
                        </Box>
                    )}
                </MenuItem>
            </Menu>
            
            {/* AppBar for Desktop */}
            {!isMobile && (
                <AppBar position="fixed">
                    <Toolbar>
                        {/* Menu Icon for Desktop */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{mr: 2}}
                        >
                            <MenuIcon/>
                        </IconButton>

                        {/* Logo */}
                        <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                            <Link to="/">
                                <img src={logo} alt="NiroApp Logo" style={{height: '40px'}}/>
                            </Link>
                        </Box>

                        {/* Navigation Links for Desktop */}
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {navLinks.map(link => (
                                <Button
                                    key={link.title}
                                    color="primary"
                                    component={Link}
                                    to={link.path}
                                    startIcon={link.icon}
                                    sx={{marginLeft: 2}}
                                >
                                    {link.title}
                                </Button>
                            ))}

                            {/* Theme Toggle */}
                            <IconButton
                                sx={{ml: 2}}
                                onClick={openThemeMenu}
                                color="inherit"
                                aria-label="Toggle theme"
                            >
                                <PaletteIcon/>
                            </IconButton>

                            {/* Login/Logout Button */}
                            {currentUser ? (
                                <Button
                                    color="secondary"
                                    onClick={handleLogout}
                                    startIcon={<LogoutIcon/>}
                                    sx={{marginLeft: 2}}
                                >
                                    Logout
                                </Button>
                            ) : (
                                <Button
                                    color="secondary"
                                    component={Link}
                                    to="/login"
                                    startIcon={<LoginIcon/>}
                                    sx={{marginLeft: 2}}
                                >
                                    Login
                                </Button>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
            )}

            {/* Swipeable Drawer */}
            <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 280,
                        boxSizing: 'border-box',
                        backgroundImage: isDarkMode
                            ? backgroundStyles[backgroundStyle].dark.gradient
                            : backgroundStyles[backgroundStyle].light.gradient,
                    },
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        height: "100%",
                        overflowY: "auto",
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <img src={logo} alt="Logo" style={{height: 36, marginRight: 8}}/>
                            <Typography variant="h6" component="div" sx={{flexGrow: 1, fontWeight: 'bold'}}>
                                NIRO
                            </Typography>
                        </Box>
                        <IconButton onClick={toggleDrawer(false)}>
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                    <Divider sx={{mb: 2}}/>
                    <List>
                        {navLinks.map(link => (
                            <ListItem button key={link.title} component={Link} to={link.path}>
                                <ListItemIcon>{link.icon}</ListItemIcon>
                                <ListItemText primary={link.title}/>
                            </ListItem>
                        ))}

                        {/* Theme Selection */}
                        <ListItem
                            button
                            onClick={openThemeMenu}
                        >
                            <ListItemIcon>
                                <PaletteIcon />
                            </ListItemIcon>
                            <ListItemText 
                                primary="Theme"
                                secondary={`${themeFamily.charAt(0).toUpperCase() + themeFamily.slice(1)} - ${isDarkMode ? 'Dark' : 'Light'}`}
                            />
                        </ListItem>

                        {/* Light/Dark Toggle */}
                        <ListItem>
                            <ListItemIcon>
                                {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                            </ListItemIcon>
                            <ListItemText primary={isDarkMode ? "Light Mode" : "Dark Mode"} />
                            <Switch checked={isDarkMode} onChange={toggleTheme} />
                        </ListItem>

                        {/* Login/Logout */}
                        {currentUser ? (
                            <ListItem button onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logout"/>
                            </ListItem>
                        ) : (
                            <ListItem button component={Link} to="/login">
                                <ListItemIcon>
                                    <LoginIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Login"/>
                            </ListItem>
                        )}
                    </List>
                </Box>
            </SwipeableDrawer>

            {/* Main Content */}
            <Box
                sx={{
                    mt: isMobile ? 0 : 8,
                    // Adjust the bottom margin to accommodate the BottomNavigation's height and safe area inset
                    // Add extra space for floating action buttons
                    mb: isMobile ? `calc(70px + env(safe-area-inset-bottom) + 16px)` : 0,
                    p: isMobile ? 0 : 2, // Reduce padding on mobile for more space
                }}
            >
                <Container 
                    maxWidth={isMobile ? false : "lg"} 
                    disableGutters={isMobile} // Remove gutters (side padding) on mobile
                    sx={{
                        width: '100%',
                        px: isMobile ? 0 : 2 // Custom padding to control exactly how much space we use
                    }}
                >
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route
                                path="/"
                                element={
                                    <PrivateRoute>
                                        <Home/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/jobs"
                                element={
                                    <PrivateRoute>
                                        <JobsPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/calendar"
                                element={
                                    <PrivateRoute>
                                        <CalendarPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/consumables"
                                element={
                                    <PrivateRoute>
                                        <ConsumablesPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/job-overview/:jobId"
                                element={
                                    <PrivateRoute>
                                        <JobOverviewPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tools"
                                element={
                                    <PrivateRoute>
                                        <ToolsPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tools/cut-optimizer"
                                element={
                                    <PrivateRoute>
                                        <CutOptimizer/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tools/cash-book"
                                element={
                                    <PrivateRoute>
                                        <CashBook/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tools/memory-game"
                                element={
                                    <PrivateRoute>
                                        <MemoryGame/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tools/quote-generator"
                                element={
                                    <PrivateRoute>
                                        <QuoteGenerator/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tools/job-cost-calculator"
                                element={
                                    <PrivateRoute>
                                        <JobCostCalculator/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/settings"
                                element={
                                    <Suspense fallback={<LoadingScreen />}>
                                        <PrivateRoute>
                                            <SettingsPage 
                                                toggleTheme={toggleTheme} 
                                                isDarkMode={isDarkMode} 
                                                themeFamily={themeFamily}
                                                changeThemeFamily={changeThemeFamily}
                                            />
                                        </PrivateRoute>
                                    </Suspense>
                                }
                            />
                        </Routes>
                    </Suspense>
                </Container>
            </Box>

            {/* Bottom Navigation for Mobile */}
            {isMobile && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: 'calc(env(safe-area-inset-bottom) + 8px)', // Increased padding for iPhone 15
                        paddingTop: '8px',
                        transform: `scale(${navBarScale})`,
                        transition: 'transform 0.2s ease-out',
                    }}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            width: '94%',
                            borderRadius: '16px',
                            overflow: 'hidden',
                            backgroundColor: isDarkMode
                                ? alpha(modernDarkTheme.palette.background.paper, 0.85)
                                : alpha(modernLightTheme.palette.background.paper, 0.85),
                            backdropFilter: 'blur(8px)',
                            boxShadow: isDarkMode
                                ? '0 4px 10px rgba(0,0,0,0.2)'
                                : '0 4px 10px rgba(0,0,0,0.08)',
                            border: isDarkMode
                                ? `1px solid ${alpha(modernDarkTheme.palette.divider, 0.1)}`
                                : `1px solid ${alpha(modernLightTheme.palette.divider, 0.1)}`,
                            position: 'relative',
                        }}
                    >
                        <BottomNavigation
                            showLabels
                            value={bottomNavValue}
                            onChange={(event, newValue) => {
                                setBottomNavValue(newValue);
                                triggerHapticFeedback();
                            }}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                                minHeight: '60px',
                                backgroundColor: 'transparent',
                                padding: '4px 8px',
                                '& .MuiBottomNavigationAction-root': {
                                    padding: '6px 0',
                                    minWidth: 0,
                                    maxWidth: '100%',
                                    transition: 'all 0.2s ease',
                                    position: 'relative',
                                },
                                '& .Mui-selected': {
                                    backgroundColor: alpha(theme.palette.primary.main, 0.08),
                                    borderRadius: '8px',
                                    '& .MuiBottomNavigationAction-label': {
                                        fontSize: '0.75rem',
                                        fontWeight: 'bold',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: theme.palette.primary.main,
                                        fontSize: '24px',
                                    }
                                },
                                '& .MuiSvgIcon-root': {
                                    fontSize: '22px',
                                    transition: 'all 0.2s ease',
                                },
                                '& .MuiBottomNavigationAction-label': {
                                    fontSize: '0.7rem',
                                    marginTop: '4px',
                                    transition: 'all 0.2s ease',
                                }
                            }}
                        >
                            <BottomNavigationAction
                                label="Startseite"
                                icon={<HomeIcon />}
                                component={Link}
                                to="/"
                                sx={{
                                    borderRadius: '8px',
                                    margin: '0 4px',
                                }}
                            />
                            <BottomNavigationAction
                                label="Aufträge"
                                icon={<WorkIcon />}
                                component={Link}
                                to="/jobs"
                                sx={{
                                    borderRadius: '8px',
                                    margin: '0 4px',
                                }}
                            />
                            <BottomNavigationAction
                                label="Kalender"
                                icon={<CalendarTodayIcon />}
                                component={Link}
                                to="/calendar"
                                sx={{
                                    borderRadius: '8px',
                                    margin: '0 4px',
                                }}
                            />
                            <BottomNavigationAction
                                label="Bestellliste"
                                icon={<LocalOfferIcon />}
                                component={Link}
                                to="/consumables"
                                sx={{
                                    borderRadius: '8px',
                                    margin: '0 4px',
                                }}
                            />
                            <BottomNavigationAction
                                label="Einstellungen"
                                icon={<SettingsIcon />}
                                component={Link}
                                to="/settings"
                                sx={{
                                    borderRadius: '8px',
                                    margin: '0 4px',
                                }}
                            />
                        </BottomNavigation>
                    </Paper>
                </Box>
            )}
        </ThemeProvider>
    );
}

function App() {
    return (
        <Router>
            <AuthProvider>
                <AppProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                        <AppContent/>
                    </LocalizationProvider>
                </AppProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
